import React, { useState, useEffect } from 'react';
import { CommonContainer } from 'components/common-container';
import { StaticImage } from 'gatsby-plugin-image';

import './PatientProfiles.scss';

const ProfileOne = ({ show, nextProfile }) => {
  const classes = show ? 'profile show' : 'profile hide';
  return (
    <div className={classes}>
      <div className="image-container">
        <StaticImage
          src="../../../../images/hcp/pages/homepage/slide-1-image.svg"
          alt="Acute Onset of Pain"
          placeholder="none"
        />
      </div>
      <div className="text-container">
        <div className="header-section">
          <h2 className="standard-bottom-spacing profile-title">
            Acute Onset of Pain
          </h2>
          <h4>Lisa, Computer Programmer</h4>
        </div>
        <p className="title no-pb">Pain history</p>
        <p className="body">None</p>
        <p className="title no-pb">Presenting concern</p>
        <p className="body">
          Acute onset of moderate knee pain after gardening over the weekend
        </p>
        <p className="title no-pb">Physician assessment</p>
        <p className="body">
          Level of pain requires the short-term management (up to 5 days) of{' '}
          <br className="hidden-smaller-screen" />
          analgesia at the opioid level
        </p>
        <p className="title no-pb">Conclusion</p>
        <p className="body">
          Prescribe 1 carton of SPRIX, with 5 single-day bottles, to provide
          treatment
          <br className="hidden-smaller-screen" /> for her moderate acute pain
        </p>
      </div>
      <div className="btn-container">
        <div className="btn" onClick={() => nextProfile(1)}>
          NEXT PROFILE
        </div>
      </div>
    </div>
  );
};
const ProfileTwo = ({ show, nextProfile }) => {
  const classes = show ? 'profile show' : 'profile hide';
  return (
    <div className={classes}>
      <div className="image-container">
        <StaticImage
          src="../../../../images/hcp/pages/homepage/slide-2-image.svg"
          alt="Acute Onset of Pain"
          placeholder="none"
        />
      </div>
      <div className="text-container">
        <div className="header-section">
          <h2 className="standard-bottom-spacing profile-title">
            MODERATELY SEVERE PAIN
          </h2>
          <h4>Scott, Construction Worker</h4>
        </div>
        <p className="title no-pb">Pain history</p>
        <p className="body">Acute low back pain</p>
        <p className="title no-pb">Presenting concern</p>
        <p className="body">
          Patient interested in non-opioid treatment options
        </p>
        <p className="title no-pb">Physician assessment</p>
        <p className="body">
          Level of pain requires the short-term management (up to 5 days) of{' '}
          <br className="hidden-smaller-screen" />
          analgesia at the opioid level
        </p>
        <p className="title no-pb">Conclusion</p>
        <p className="body">
          Prescribe 1 carton of SPRIX, with 5 single-day bottles, at
          pre-procedural
          <br className="hidden-smaller-screen" /> consult as a non-narcotic
          treatment for moderate to moderately severe acute pain
        </p>
      </div>
      <div className="btn-container">
        <div className="btn" onClick={() => nextProfile(2)}>
          NEXT PROFILE
        </div>
      </div>
    </div>
  );
};
const ProfileThree = ({ show, nextProfile }) => {
  const classes = show ? 'profile show' : 'profile hide';
  return (
    <div className={classes}>
      <div className="image-container">
        <StaticImage
          src="../../../../images/hcp/pages/homepage/slide-3-image.svg"
          alt="Acute Onset of Pain"
          placeholder="none"
        />
      </div>
      <div className="text-container">
        <div className="header-section">
          <h2 className="standard-bottom-spacing profile-title">
            POST-SURGICAL PAIN
          </h2>
          <h4>David, High School Teacher</h4>
        </div>
        <p className="title no-pb">Pain history</p>
        <p className="body">Rotator cuff tear requiring arthroscopic repair</p>
        <p className="title no-pb">Presenting concern</p>
        <p className="body">
          Scheduled for surgery; need to plan for post-surgery pain control
        </p>
        <p className="title no-pb">Physician assessment</p>
        <p className="body">
          Patient prefers a short-term opioid alternative to manage moderate to{' '}
          <br className="hidden-smaller-screen" />
          moderately severe acute pain
        </p>
        <p className="title no-pb">Conclusion</p>
        <p className="body">
          Prescribe 1 carton of SPRIX, with 5 single-day bottles, at
          pre-surgical
          <br className="hidden-smaller-screen" /> consult for discharge
          treatment
        </p>
      </div>
      <div className="btn-container">
        <div className="btn" onClick={() => nextProfile(0)}>
          NEXT PROFILE
        </div>
      </div>
    </div>
  );
};

const PatientProfilesContainer = () => {
  const [profileList, setProfileList] = useState([true, false, false]);

  const handleNextProfile = nextProfile => {
    console.log('next profile', nextProfile);
    const updated = new Array(profileList.length).fill(false);
    updated[nextProfile] = true;
    setProfileList(updated);
  };

  return (
    <>
      <ProfileOne show={profileList[0]} nextProfile={handleNextProfile} />
      <ProfileTwo show={profileList[1]} nextProfile={handleNextProfile} />
      <ProfileThree show={profileList[2]} nextProfile={handleNextProfile} />
    </>
  );
};

const DosageAdministration = () => {
  return (
    <div className="dosage-administration">
      <h5>Dosage and Administration</h5>
      <ul>
        <li>
          Use the lowest effective dosage for shortest duration consistent with
          individual treatment goals
        </li>
        <li>
          SPRIX is not an inhaled product. For adult patients &lt; 65 years of
          age: 31.5 mg (one 15.75 mg spray in each nostril) every 6 to 8
          <br className="hidden-smaller-screen" /> hours. The maximum daily dose
          is 126 mg
        </li>
        <li>
          For patients ≥ 65 years of age, renally impaired patients, and
          patients less than 50 kg (110 lbs): 15.75 mg (one 15.75 mg spray in
          <br className="hidden-smaller-screen" />
          only one nostril) every 6 to 8 hours. The maximum daily dose is 63 mg
        </li>
        <li>
          SPRIX nasal spray should be discarded within 24 hours of taking the
          first dose, even if the bottle still contains some medication
        </li>
      </ul>
    </div>
  );
};

export const PatientProfiles = props => {
  return (
    <div className="profile-container" id="profiles">
      <CommonContainer>
        <h2 className="standard-top-spacing">
          <span className="bold">PATIENT PROFILES:</span> ADULT PATIENTS WHO
          EXPERIENCE ACUTE PAIN
        </h2>
        <h4 className="light-blue standard-bottom-spacing">
          SPRIX is a non-narcotic medication delivering short-term (for up to 5
          days) opioid-level analgesia to manage
          <br className="hidden-smaller-screen" /> moderate to moderately severe
          pain that requires analgesia at the opioid level
          <sup className="light-blue">1</sup>
        </h4>
        <PatientProfilesContainer />
        <DosageAdministration />
      </CommonContainer>
    </div>
  );
};

import React, { useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import { Homepage } from 'components/pages/homepage';
import { PatientProfiles } from 'components/pages/homepage/PatientProfiles';
import { CommonContainer } from 'components/common-container';

import './index.scss';

const pageTitle = '';
const pageDescription = '';

const AboutSprix = () => {
  return (
    <div className="homepage-about-sprix" id="about">
      <CommonContainer>
        <h3>
          ABOUT SPRIX:{' '}
          <span className="regular default-gray">
            OPIOID-LEVEL ANALGESIA<sup>1</sup>
          </span>
        </h3>
        <h4>
          A nasal spray formulation of ketorolac, an NSAID (nonsteroidal
          anti-inflammatory drug), and a proven pain reliever
        </h4>
        <Row middle="xs">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../images/hcp/pages/homepage/homepage-calendar.png"
              placeholder="none"
              alt="Sprix Nasal Spray"
            />
          </Col>
          <Col md={12} lg={10} className="right-content-section">
            <p className="main-blue bold">
              SPRIX is for short-term (up to 5 days) pain relief in adult
              patients to manage
              <br className="hidden-smaller-screen" /> moderate to moderately
              severe pain that requires analgesia at the opioid level
              <sup>1</sup>
            </p>
          </Col>
        </Row>
        <Row className="empty-row first">
          <Col md={12} lg={2} className="left-image">
            &nbsp;
          </Col>
          <Col md={12} lg={10} className="empty-col right-content-section">
            &nbsp;
          </Col>
        </Row>
        <Row middle="md" className="row-border">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../images/hcp/pages/homepage/sprix-bottle.png"
              placeholder="none"
              alt="SPRIX is a potent NSAID and is NOT a controlled substance"
            />
          </Col>
          <Col md={12} lg={10} className="col-border right-content-section">
            <p className="main-blue bold">
              SPRIX is a potent NSAID and is NOT a controlled substance
            </p>
          </Col>
        </Row>
        <Row className="empty-row">
          <Col md={12} lg={2} className="left-image">
            &nbsp;
          </Col>
          <Col md={12} lg={10} className="empty-col right-content-section">
            &nbsp;
          </Col>
        </Row>
        <Row middle="xs" className="row-border">
          <Col md={12} lg={2} className="left-image">
            <StaticImage
              src="../images/hcp/pages/homepage/nasal-spray.png"
              placeholder="none"
              alt="HOW DO I GET SPRIX?"
            />
          </Col>
          <Col md={12} lg={10} className="col-border right-content-section">
            <p className="main-blue bold">
              Ketorolac strength in the speed of a nasal spray.
              <br className="hidden-smaller-screen" /> Efficacy within 20-30
              minutes<sup>2,3</sup>
            </p>
          </Col>
        </Row>
      </CommonContainer>
    </div>
  );
};

const IndexPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.hcp} className="hcp-home">
      <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <Homepage />
        </div>
      </div>
      <AboutSprix />
      <PatientProfiles />
    </Layout>
  );
};

export default IndexPage;

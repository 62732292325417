import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Row, Col } from 'react-flexbox-grid';

import { Button } from 'components/button';

import animatedImage from '../../../../images/common/homepage/homepage-pain-areas.gif';
import animatedImageMobile from '../../../../images/common/homepage/homepage-pain-areas-mobile.gif';
import { Carousel } from 'components/pages/homepage/Carousel';
import './index.scss';

const FirstRow = () => {
  return (
    <>
      <Col xs={12} md={6} className="align-center dark-blue-container">
        <div className="hero-left-container">
          <div className="image-container show-only-desktop">
            <StaticImage
              src="../../../../images/hcp/pages/homepage/hero-sprix-bottle.png"
              placeholder="none"
              alt="sprix bottle"
            />
          </div>
          <div className="col-container">
            <div className="img-container">
              <StaticImage
                src="../../../../images/hcp/pages/homepage/powerful-relief.png"
                placeholder="none"
                alt="Powerful Relief For Pain"
              />
            </div>
            <div className="txt-container">
              <div className="txt-content">
                The efficacy of SPRIX<sup>&reg;</sup> (ketorolac tromethamine)
                Nasal <br className="hidden-smaller-screen" />
                Spray was demonstrated by a statistically significant
                <br className="hidden-smaller-screen" /> reduction in pain vs
                placebo in an acute pain study of
                <br className="hidden-smaller-screen" /> post-abdominal or
                -orthopedic surgery patients and in a
                <br className="hidden-smaller-screen" /> second study of
                post-abdominal surgery patients
                <sup>1-4</sup>
              </div>
            </div>
            <div className="btn-container">
              <Button
                href="/clinical-data"
                value="SEE CLINICAL DATA"
                width="220px"
              />
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} md={6} className="align-center gif-image">
        <Carousel />
        {/* <div className="display-only-mobile">
          <img
            src={animatedImageMobile}
            className="img-max-width "
            alt="Pain Areas"
          />
        </div>

        <img
          src={animatedImage}
          className="img-max-width display-non-mobile"
          alt="Pain Areas"
        /> */}
      </Col>
    </>
  );
};

const SecondRow = () => {
  return (
    <div className="dark-rounded-box">
      <Row between="md" middle="md">
        <Col xs={12} md={2}>
          <StaticImage
            src="../../../../images/common/homepage/scriptsRx.png"
            placeholder="none"
            alt="Scripts RX"
            width={179}
            height={60}
          />
        </Col>
        <Col center="md" xs={12} md={8}>
          <div className="rounded-box-text">
            <span>
              SPRIX IS ONLY AVAILABLE THROUGH SCRIPTS Rx:{' '}
              <br className="show-only-desktop" />
              ACCESS THE{' '}
              <a
                className="prescribing-form"
                href="/pdfs/direct-prescription-form-chsp.pdf"
                target="_blank"
              >
                PRESCRIBING FAX FORM
              </a>
            </span>
          </div>
        </Col>
        <Col xs={12} md={2}>
          <div className="btn-container">
            <Button
              href="/get-in-touch#sprix-direct"
              value="LEARN MORE"
              width="100%"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ThirdRow = () => {
  return (
    <div className="hero-last-row">
      <Row top="md">
        <Col md={12} lg={7}>
          <Row>
            <Col sm={12}>
              <p className="light-gray">
                Fax completed Scripts Rx form to{' '}
                <span className="bold">(844) 794-7275</span>. Write clearly to
                help automate
                <br className="hidden-smaller-screen" /> processing so the
                prescription will reach your patients faster.
              </p>
              <div className="bold locate-scripts">
                Locate Scripts Rx in your EMR system by searching:
              </div>
              <div className="bkg-blue">
                <Row className="address-row">
                  <Col sm={12} md={3} className="address-col">
                    ADDRESS
                  </Col>
                  <Col sm={12} md={9} className="address-col">
                    1815 S. Meyers Road, Suite 100 <br />
                    Oakbrook Terrace, IL 60181
                  </Col>
                </Row>
              </div>
              <div className="bkg-blue no-bkg">
                <Row>
                  <Col xs={5} md={3}>
                    NCPDP/NABP
                  </Col>
                  <Col xs={7} md={9}>
                    1468481
                  </Col>
                </Row>
              </div>
              <div className="bkg-blue">
                <Row>
                  <Col xs={5} md={3}>
                    NPI
                  </Col>
                  <Col xs={7} md={9}>
                    110 498 2230
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={5} className="left-section">
          <Row>
            <Col xs={3}>
              <StaticImage
                src="../../../../images/hcp/pages/homepage/dollar-zero.png"
                placeholder="none"
                alt="$0 copay available for patients with eligible commercial insurance"
              />
            </Col>
            <Col xs={9} className="text-container">
              $0 copay available for patients with
              <br className="hidden-smaller-screen" /> eligible commercial
              insurance
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <StaticImage
                src="../../../../images/hcp/pages/homepage/prior-authorization.png"
                placeholder="none"
                alt="No prior authorization completion is required for patients with
              eligible commercial insurance"
              />
            </Col>
            <Col xs={9} className="text-container">
              No prior authorization completion is
              <br className="hidden-smaller-screen" /> required for patients
              with eligible
              <br className="hidden-smaller-screen" /> commercial insurance
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <StaticImage
                src="../../../../images/hcp/pages/homepage/delivered.png"
                placeholder="none"
                alt="Prescription delivered quickly and without contact to your
              patient’s home"
              />
            </Col>
            <Col xs={9} className="text-container">
              Prescription delivered quickly and
              <br className="hidden-smaller-screen" /> without contact to your
              patient’s home
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const PatientProfile = () => {};

export const Homepage = props => {
  return (
    <div className="homepage-container">
      <div className="homepage-inner-container">
        <Row between="md standard-bottom-spacing">
          <FirstRow />
        </Row>
        <SecondRow />
        <ThirdRow />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './Carousel.scss';

const PrevButton = ({ handlePrev }) => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/icon-prev.png"
    placeholder="none"
    alt="Previous"
    className="btn-action btn-prev"
    onClick={handlePrev}
  />
);

const NextButton = ({ handleNext }) => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/icon-next.png"
    placeholder="none"
    alt="Next"
    className="btn-action btn-next"
    onClick={handleNext}
  />
);

const PainManagement = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/pain-management.png"
    placeholder="none"
    alt="Pain Management"
    width="89"
    height="89"
  />
);

const ActivePainManagement = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-pain-management.png"
    placeholder="none"
    alt="Pain Management"
    width="89"
    height="89"
  />
);

const renderPainMgmt = isActive =>
  isActive ? <ActivePainManagement /> : <PainManagement />;

const renderPainMgmtText = isActive => {
  return (
    isActive && (
      <>
        <div className="text-header">
          <h2 className="default-yellow">PAIN MANAGEMENT</h2>
          <h4>Acute and post-procedural/surgical pain</h4>
        </div>
        <div className="list-container">
          <div className="left">
            <div className="list">Neck pain</div>
            <div className="list">Back pain</div>
          </div>
          <div className="right">
            <div className="list">Muscle pain</div>
            <div className="list">Radiofrequency ablation</div>
          </div>
        </div>
      </>
    )
  );
};

const ActiveNeurology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-neurology.png"
    placeholder="none"
    alt="Neurology"
    width="89"
    height="89"
  />
);

const Neurology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/neurology.png"
    placeholder="none"
    alt="Neurology"
    width="89"
    height="89"
  />
);

const renderNeurology = isActive =>
  isActive ? <ActiveNeurology /> : <Neurology />;

const renderNeurologyText = isActive => {
  return (
    isActive && (
      <>
        <div className="text-header">
          <h2 className="default-blue">NEUROLOGY</h2>
          <h4>Acute and post-procedural/surgical pain</h4>
        </div>
        <div className="list-container">
          <div className="left">
            <div className="list">Headache pain</div>
            <div className="list">Neck pain</div>
          </div>
          <div className="right">
            <div className="list">Back pain</div>
            <div className="list">Lumbar puncture</div>
          </div>
        </div>
      </>
    )
  );
};

const Orthopedics = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/orthopedics.png"
    placeholder="none"
    alt="Orthopedics"
    width="89"
    height="89"
  />
);

const ActiveOrthopedics = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-orthopedics.png"
    placeholder="none"
    alt="Orthopedics"
    width="89"
    height="89"
  />
);

const renderOrthopedics = isActive =>
  isActive ? <ActiveOrthopedics /> : <Orthopedics />;

const renderOrthopedicsText = isActive =>
  isActive && (
    <>
      <div className="text-header">
        <h2 className="default-light-blue">Orthopedics</h2>
        <h4>Acute and post-procedural/surgical pain</h4>
      </div>
      <div className="list-container">
        <div className="left">
          <div className="list">Neck and back pain</div>
          <div className="list">Muscle pain</div>
        </div>
        <div className="right">
          <div className="list">Joint replacement</div>
          <div className="list">Arthroscopy</div>
        </div>
      </div>
    </>
  );

const Urology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/urology.png"
    placeholder="none"
    alt="Urology"
    width="89"
    height="89"
  />
);

const ActiveUrology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-urology.png"
    placeholder="none"
    alt="Urology"
    width="89"
    height="89"
  />
);

const renderUrology = isActive => (isActive ? <ActiveUrology /> : <Urology />);

const renderUrologyText = isActive =>
  isActive && (
    <>
      <div className="text-header">
        <h2 className="default-yellow">UROLOGY</h2>
        <h4>Acute and post-procedural/surgical pain</h4>
      </div>
      <div className="list-container">
        <div className="left">
          <div className="list">Kidney stones</div>
          <div className="list">Interstitial cystitis</div>
        </div>
        <div className="right">
          <div className="list">Vasectomy</div>
          <div className="list">Urolift (prostate lift)</div>
        </div>
      </div>
    </>
  );

const Podiatry = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/podiatry.png"
    placeholder="none"
    alt="Podiatry"
    width="89"
    height="89"
  />
);
const ActivePodiatry = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-podiatry.png"
    placeholder="none"
    alt="Podiatry"
    width="89"
    height="89"
  />
);

const renderPodiatry = isActive =>
  isActive ? <ActivePodiatry /> : <Podiatry />;

const renderPodiatryText = isActive =>
  isActive && (
    <>
      <div className="text-header">
        <h2 className="default-blue">Podiatry</h2>
        <h4>Acute and post-procedural/surgical pain</h4>
      </div>
      <div className="list-container">
        <div className="left">
          <div className="list">Plantar fasciitis</div>
          <div className="list">Heel pain</div>
        </div>
        <div className="right">
          <div className="list">Hammertoe</div>
          <div className="list">Bunionectomy</div>
        </div>
      </div>
    </>
  );

const Gynecology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/gynecology.png"
    placeholder="none"
    alt="Gynecology"
    width="89"
    height="89"
  />
);

const ActiveGynecology = () => (
  <StaticImage
    src="../../../../images/hcp/pages/homepage/active-gynecology.png"
    placeholder="none"
    alt="Gynecology"
    width="89"
    height="89"
  />
);

const renderGynecology = isActive =>
  isActive ? <ActiveGynecology /> : <Gynecology />;

const renderGynecologyText = isActive =>
  isActive && (
    <>
      <div className="text-header">
        <h2 className="default-light-blue">Gynecology</h2>
        <h4>Acute and post-procedural/surgical pain</h4>
      </div>
      <div className="list-container">
        <div className="left">
          <div className="list">Dysmenorrhea</div>
          <div className="list">Endometriosis</div>
        </div>
        <div className="right">
          <div className="list">Hysterectomy</div>
        </div>
      </div>
    </>
  );

export const Carousel = props => {
  const [icons, setIcons] = useState([true, false, false, false, false, false]);
  const [activeIcon, setActiveIcon] = useState(0);

  const handleInterval = () => {
    setActiveIcon(prev => {
      return prev + 1 < icons.length ? prev + 1 : 0;
    });
  };

  const handleNext = () => {
    setActiveIcon(prev => {
      return prev + 1 < icons.length ? prev + 1 : 0;
    });
  };

  const handlePrev = () => {
    setActiveIcon(prev => {
      return prev - 1 < 0 ? icons.length - 1 : prev - 1;
    });
  };

  useEffect(() => {
    setInterval(handleInterval, 5000);
  }, []);

  useEffect(() => {
    const newIconsList = icons.slice().fill(false);
    newIconsList[activeIcon] = true;
    setIcons(newIconsList);
  }, [activeIcon]);

  return (
    <div className="carousel-container">
      <div className="carousel-inner-container">
        <div className="carousel-header bold">
          Help manage moderate to moderately severe pain{' '}
          <br className="display-non-mobile" /> across multiple pain areas
        </div>
        <div className="carousel-body">
          <div className="icons-container">
            <PrevButton handlePrev={handlePrev} />
            <div className="icons">
              {renderPainMgmt(icons[0])}
              {renderNeurology(icons[1])}
              {renderOrthopedics(icons[2])}
              {renderUrology(icons[3])}
              {renderPodiatry(icons[4])}
              {renderGynecology(icons[5])}
            </div>
            <NextButton handleNext={handleNext} />
          </div>
          <div className="carousel-bottom">
            <PrevButton handlePrev={handlePrev} />
            <div className="text-container">
              {renderPainMgmtText(icons[0])}
              {renderNeurologyText(icons[1])}
              {renderOrthopedicsText(icons[2])}
              {renderUrologyText(icons[3])}
              {renderPodiatryText(icons[4])}
              {renderGynecologyText(icons[5])}
            </div>
            <NextButton handleNext={handleNext} />
          </div>
        </div>
      </div>
    </div>
  );
};
